import {
  UPSELL,
  ADD_TO_CART,
  PREVIEW,
} from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell/analytics/constant"

export const addAnalyticsForAddToCart = (
  item,
  productPrice,
  discountedPrice,
  productImageUrl
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageload} ${PREVIEW}:${UPSELL}:${ADD_TO_CART}`,
    eventMsg: "n/a",
    eventName: `${pageload} ${PREVIEW}:${UPSELL}:${ADD_TO_CART}`,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: ADD_TO_CART,
    internalLinkPosition: `${pageload} ${PREVIEW}:upsell`,
    internalLinkType: `${pageload}:${ADD_TO_CART}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload} ${PREVIEW}:our best sellers`,
  }
  pushAnalyticsObjToDataLayer(
    eventInfo,
    item,
    productPrice,
    discountedPrice,
    productImageUrl
  )
}

const getproductInfo = (
  item,
  productPrice,
  discountedPrice,
  productImageUrl
) => {
  const {
    CustomerFacingBrand_s: brandName = "",
    sku_s: skuID = "",
    ProductBrandName_s: name = "",
    ProductDescriptionProductShort_s: desc = "",
    Product_Category: category = "",
  } = item
  const productInfo = {
    description: desc,
    frequency: "n/a",
    globalPromotionPrice:
      discountedPrice > 0 ? Number((productPrice - 0).toFixed(2)) : 0,
    globalOffer: "n/a",
    globalDiscount: Number(discountedPrice.toFixed(2)),
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountedPrice ? "discounted price" : "regular price",
    productBasePrice: Number(productPrice),
    productCategory: category,
    productColor: "default",
    productCoupondiscount: "n/a",
    productCouponCode: "n/a",
    productID: skuID,
    productName: name || desc || category || "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: "service" || "n/a",
    productSalePrice: Number(productPrice),
    productSaleable: true,
    productStatus: "in stock",
    productSuperSku: false,
    productTileName: name,
    quantity: 1,
    defaultImageName: productImageUrl,
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: category,
    productBrand: brandName,
    itemType: "service" || "n/a",
  }
  return productInfo
}
const pushAnalyticsObjToDataLayer = (
  eventInfo = {},
  item,
  productPrice,
  discountedPrice,
  productImageUrl
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const productInfo = getproductInfo(
    item,
    productPrice,
    discountedPrice,
    productImageUrl
  )
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
    productInfo,
  })
}
