import React from "react"
import {
  ImageListViewMobile,
  ImageGridViewMobile,
  ListViewSelected,
  GridViewUnSelected,
} from "@/components/PartsViewer/v2/icon.js"

const ToggleView = props => {
  const { listView, setlistView, device } = props
  const isDesktop = device.isExtraLargeScreen || device.isLargeScreen
  return (
    <>
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <button
          className={`btn-expand grid-btn border-0 ${
            isDesktop || device?.isMediumScreen ? "pr-15" : "pr-15 pl-20"
          } ${listView ? "" : "active"}`}
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            setlistView(false)
          }}
          data-testid="select-grid-view"
        >
          {!listView ? <ImageGridViewMobile /> : <GridViewUnSelected />}
          {isDesktop ? (
            <span className="tool-tip-custom bottom grid-list-view">
              Grid View
            </span>
          ) : null}
        </button>

        <button
          className={`btn-expand list-btn border-0 ${listView ? "active" : ""}`}
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            setlistView(true)
          }}
        >
          {listView ? <ListViewSelected /> : <ImageListViewMobile />}
          {isDesktop ? (
            <span className="tool-tip-custom bottom grid-list-view">
              List View
            </span>
          ) : null}
        </button>
      </div>
    </>
  )
}

export default ToggleView
