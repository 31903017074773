import React, { useEffect, useState } from "react"
import { isImgUrlValid } from "@/services/ImageUrlValid"
import useViewport from "@/hooks/useViewport"
import { getPersonaPriceDetails } from "@/components/PartsViewer/v2/helper"
import swatchMissing from "@/public/images/swatch-missing.jpeg"
import CONSTANTS from "@/constants/index"
import ListCardLoading from "@/components/PartsViewer/v2/GridAndListPanel/ListView/LoadingCard"
import commingSoon from "@/public/scout_icon/coming-soon.jpg"

const ListView = props => {
  const { dataPromise, showPopup, isListExtend, dataInput } = props
  const [validUrl, setValidUrl] = useState({ status: "loading" })
  const [loading, setLoading] = useState("notStarted")
  const [productSwatche, setProductSwatche] = useState({})
  const device = useViewport()
  const [colour, setColour] = useState()
  const [selectedVariant, setSelectedVariant] = useState(null)
  const {
    Name: name,
    productId,
    imageUrl: image,
    priceInfo,
    productInventoryInfo,
  } = productSwatche
  const personaPriceList = getPersonaPriceDetails(dataInput)

  useEffect(() => {
    getListData()
  }, [])

  useEffect(() => {
    updateImgUrl()
  }, [productSwatche?.imageUrl])

  const updateImgUrl = async () => {
    if (productSwatche?.imageUrl) {
      const res = await isImgUrlValid(productSwatche?.imageUrl)
      if (res) {
        setValidUrl({ status: "success" })
      } else {
        setValidUrl({ status: "failed" })
      }
    } else {
      setValidUrl({ status: "failed" })
    }
  }

  const getListData = async () => {
    setLoading("loading")
    const res = await dataPromise
    const { Name, productId, productIdKey, data: list, activeItem } = res
    const imageUrl = list?.colorData[0]?.imgUrl
    const priceInfo = list?.colorData?.filter(
      item => item.id === activeItem
    )?.[0]
    const productInventoryInfo = list?.varientDetails?.filter(
      item => item.sku === activeItem
    )?.[0]
    setProductSwatche({
      Name,
      productId,
      imageUrl,
      productIdKey,
      priceInfo,
      productInventoryInfo,
    })
    if (res?.data) {
      const getSwatches = list?.varientDetails
      const filterSwatch = getSwatches.filter(
        val => val["swatch"] != "swatch_NA"
      )
      const colorArray = personaPriceList.map(item => {
        const matchItem = Object.values(filterSwatch).find(
          element => item.sku === element.sku
        )
        if (matchItem) return { ...item, ...matchItem }
        else return item
      })
      setColour(colorArray)
    }
    setLoading("loaded")
  }

  if (loading != "loaded") return <ListCardLoading />

  const colorCount = 5
  let extraColor
  if (colour?.length > colorCount) {
    extraColor = colour?.length - colorCount
  }
  let marginStyle
  let flexDirection
  const forMediumScreen = device?.isMediumScreen && !isListExtend

  if (forMediumScreen) {
    marginStyle = "mx-0"
    flexDirection = "flex-column"
  } else if (colour?.length > 1) {
    if (device?.isExtraSmallScreen) {
      marginStyle = "ml-0"
    } else {
      marginStyle = "ml-10 tag-discontinue-position"
    }
  }
  const colorSwatchDefaultImg = e => {
    e.target.src = swatchMissing.src
  }
  const forAllSmallScreen =
    device.isMediumScreen || device?.isSmallScreen || device?.isExtraSmallScreen

  const skuPriceClass =
    isListExtend && !device.isMediumScreen
      ? "justify-content-end w-50" // mx-auto
      : !isListExtend && device.isMediumScreen
      ? "mx-0 p-0 mt-5"
      : "ml-2 px-1"

  let titleAlignCenter = ""
  if (isListExtend && (device.isExtraLargeScreen || device.isLargeScreen)) {
    if (
      !productInventoryInfo?.discontinued &&
      !(
        productInventoryInfo?.isStock === "false" &&
        productInventoryInfo?.discontinued === false
      )
    ) {
      titleAlignCenter = true
    }
  }

  return (
    <div
      className={`card border-active list-section`}
      onClick={() => {
        showPopup(productId)
      }}
      data-testid="list-card"
      onKeyDown={() => {
        showPopup(productId)
      }}
    >
      <div className={`card-bodys d-flex justify-content-between`}>
        <div className="d-flex w-100 flex-grow-1">
          <div className="thumb-list">
            {validUrl?.status === "loading" ? (
              <div className="loading-spinner">
                <div className="shimmer-loading__image shimmer"></div>
              </div>
            ) : (
              <img
                width="60px"
                height="60px"
                className="card-img"
                src={validUrl.status === "success" ? image : commingSoon?.src}
                alt={name}
              />
            )}
          </div>
          <div
            className={`d-flex justify-content-between w-100 flex-grow-1 
                ${forAllSmallScreen ? "flex-column" : ""}
                ${
                  isListExtend && !device?.isMediumScreen
                    ? "flex-row ms-4"
                    : "flex-column"
                } pt-2 pb-2`}
          >
            <div className="d-flex flex-column">
              <div
                className={`color-variants list-color ${
                  forMediumScreen ? "d-block" : "color-parts"
                }`}
              >
                {colour?.length > 1 ? (
                  colour?.slice(0, colorCount)?.map(item =>
                    item?.swatch !== undefined ? (
                      <label
                        key={item["swatch"]}
                        className="other_color"
                        onMouseEnter={() => setSelectedVariant(item)}
                        onMouseLeave={() => setSelectedVariant(null)}
                      >
                        <img
                          width="20px"
                          height="20px"
                          className="img_variant_color"
                          src={CONSTANTS.COLOR_FILE_URL + item["swatch"]}
                          alt={item["name"]}
                          onError={e => colorSwatchDefaultImg(e)}
                          title={
                            selectedVariant ? selectedVariant[0] : item["name"]
                          }
                        />
                      </label>
                    ) : (
                      ""
                    )
                  )
                ) : (
                  <>
                    {titleAlignCenter ? (
                      <div className="title-center-align"></div>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {extraColor ? (
                  <label className="extra-color">+{extraColor}</label>
                ) : (
                  ""
                )}

                {(
                  selectedVariant
                    ? selectedVariant?.discontinued
                    : productInventoryInfo?.discontinued
                ) ? (
                  <label
                    className={`fs-13 fw-600 tag-discontinue discontinued label-discontinued ${marginStyle}`}
                  >
                    Discontinued
                  </label>
                ) : (
                  ""
                )}

                {(
                  selectedVariant
                    ? !selectedVariant?.discontinued &&
                      selectedVariant?.isStock === "false"
                    : productInventoryInfo?.isStock === "false" &&
                      productInventoryInfo?.discontinued === false
                ) ? (
                  <label
                    className={`fs-13 fw-600 tag-discontinue outof-stoke label-discontinued ${marginStyle} `}
                  >
                    out of stock
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div className="d-flex align-items-center justify-content-between mb-5">
                <p
                  fontSize={18}
                  className={`card-text fw-400 tablet-view-padding ${
                    isListExtend ? "model-name-list " : "model-name list-items"
                  } text-capitalize`}
                >
                  {name?.toLowerCase()}
                </p>

                <button className="list right-screen add-to-cart">Add</button>
              </div>
            </div>
            <div
              className={`d-flex card-text fw-400 align-items-center list-details list-fullwidth-tab ${
                isListExtend ? "list-fullwidth" : ""
              }`}
            >
              <div
                className={`model-name d-flex ${flexDirection} ${
                  isListExtend && !device.isMediumScreen
                    ? "justify-content-end w-75" // mx-auto
                    : ""
                }`}
              >
                <p fontSize={14} className="sku-name">
                  {selectedVariant
                    ? selectedVariant?.customerSku
                    : priceInfo?.value}
                </p>

                <p
                  fontSize={14}
                  className={`model-prices d-flex  
                      ${skuPriceClass}`}
                >
                  <span className={`sku_product`}>
                    <del>
                      {selectedVariant
                        ? selectedVariant?.currencySign +
                          selectedVariant?.pricelist
                        : priceInfo?.currencySign + priceInfo?.pricelist}
                    </del>
                  </span>
                  <span className="model-name-price ml-2 px-1">
                    {selectedVariant
                      ? selectedVariant?.currencySign + selectedVariant?.price
                      : priceInfo?.currencySign + priceInfo?.price}
                  </span>
                </p>
              </div>

              <button className="list add-to-cart">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListView
