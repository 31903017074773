import React, { useEffect, useState } from "react"
import useViewport from "@/hooks/useViewport"
import { isImgUrlValid } from "@/services/ImageUrlValid"
import { getPersonaPriceDetails } from "@/components/PartsViewer/v2/helper.js"
import GridCardLoading from "@/components/PartsViewer/v2/GridAndListPanel/GridView/LoadingGrid"
import CONSTANTS from "@/constants/index"
import swatchMissing from "@/public/images/swatch-missing.jpeg"
import commingSoon from "@/public/scout_icon/coming-soon.jpg"

const GridView = props => {
  const { dataPromise, showPopup, isListExtend, dataInput } = props
  const [validUrl, setValidUrl] = useState({ status: "loading" })
  const [loading, setLoading] = useState("notStarted")
  const [productSwatche, setProductSwatche] = useState({})
  const [colour, setColour] = useState()
  const [selectedVariant, setSelectedVariant] = useState(null)
  const device = useViewport()
  const {
    Name: name,
    productId,
    imageUrl: image,
    priceInfo,
    productInventoryInfo,
  } = productSwatche
  const personaPriceList = getPersonaPriceDetails(dataInput)

  useEffect(() => {
    getGridItems()
    return () => {
      setProductSwatche({})
      setLoading("loading")
    }
  }, [])

  useEffect(() => {
    updateImageUrl()
    return () => {
      setValidUrl({})
    }
  }, [productSwatche?.imageUrl])

  const updateImageUrl = async () => {
    if (productSwatche?.imageUrl) {
      const res = await isImgUrlValid(productSwatche?.imageUrl)
      if (res) {
        setValidUrl({ status: "success" })
      } else {
        setValidUrl({ status: "failed" })
      }
    } else {
      setValidUrl({ status: "failed" })
    }
  }

  const getGridItems = async () => {
    setLoading("loading")
    const res = await dataPromise
    if (res) {
      const {
        Name,
        productId,
        skuId,
        data,
        productIdKey,
        data: list,
        activeItem,
      } = res
      const imageUrl = data?.colorData?.[0]?.imgUrl
      const priceInfo = list?.colorData?.filter(
        item => item.id === activeItem
      )?.[0]
      const productInventoryInfo = list?.varientDetails?.filter(
        item => item.sku === activeItem
      )?.[0]

      setProductSwatche({
        Name,
        productId,
        skuId,
        imageUrl,
        productIdKey,
        priceInfo,
        productInventoryInfo,
      })
      if (list) {
        const getSwatches = list["varientDetails"]
        const filterSwatch = getSwatches?.filter(
          val => val["swatch"] != "swatch_NA"
        )
        const colorArray = personaPriceList.map(item => {
          const matchItem = Object.values(filterSwatch).find(
            element => item.sku === element.sku
          )
          if (matchItem) return { ...item, ...matchItem }
          else return item
        })
        setColour(colorArray)
      }
      setLoading("loaded")
    }
  }

  if (loading != "loaded")
    return <GridCardLoading isListExtend={isListExtend} />
  const colorSwatchDefaultImg = e => {
    e.target.src = swatchMissing.src
  }
  const colorCount =
    isListExtend && (device?.isLargeScreen || device?.isExtraLargeScreen)
      ? 7
      : 5
  let extraColor
  if (colour?.length > colorCount) {
    extraColor = colour?.length - colorCount
  }
  let marginStyle = ""
  if (colour?.length > 0) {
    marginStyle = "mb-8"
  }
  return (
    <div
      className="card"
      onClick={() => {
        showPopup(productId)
      }}
      data-testid="grid-card"
      onKeyDown={() => {
        showPopup(productId)
      }}
    >
      <div className={`thumb-size ${isListExtend ? "grid-fullwidth" : ""}`}>
        {validUrl?.status === "loading" ? (
          <div className="loading-spinner border-0">
            <div className="shimmer-loading__image shimmer"></div>
          </div>
        ) : (
          <img
            className="card-img-top"
            src={validUrl.status === "success" ? image : commingSoon.src}
            alt={name}
          />
        )}
      </div>

      <div className={`card-bodys grid ${colour?.length <= 0 ? "mt-3" : ""}`}>
        <div className="product-information">
          <div className={`color-variants ${marginStyle}`}>
            {colour?.slice(0, colorCount)?.map(item =>
              item["swatch"] !== undefined ? (
                <label
                  key={item["swatch"]}
                  className="other_color"
                  onMouseEnter={() => setSelectedVariant(item)}
                  onMouseLeave={() => setSelectedVariant(null)}
                >
                  <img
                    width="20px"
                    height="20px"
                    className="img_variant_color"
                    src={CONSTANTS.COLOR_FILE_URL + item["swatch"]}
                    alt={item["name"]}
                    onError={e => colorSwatchDefaultImg(e)}
                    title={selectedVariant ? selectedVariant[0] : item["name"]}
                  />
                </label>
              ) : null
            )}
            {extraColor ? (
              <label className="extra-color">+{extraColor}</label>
            ) : null}
          </div>

          {(
            selectedVariant
              ? selectedVariant?.discontinued
              : productInventoryInfo?.discontinued
          ) ? (
            <p
              className={`fs-13 fw-600 tag-discontinue discontinued mb-5 label-discontinued`}
            >
              Discontinued
            </p>
          ) : null}

          {(
            selectedVariant
              ? !selectedVariant?.discontinued &&
                selectedVariant?.isStock === "false"
              : productInventoryInfo?.isStock === "false" &&
                productInventoryInfo?.discontinued === false
          ) ? (
            <p
              className={`fs-13 fw-600 tag-discontinue mb-5 outof-stoke label-discontinued `}
            >
              out of stock
            </p>
          ) : null}
          <div className="card-model">
            <p className="card-text fw-400 model-number card-product-title text-capitalize mb-5">
              {name?.toLowerCase()}
            </p>
            <p
              className={`card-text fw-400 text-capitalize sku_product-description mb-5 ${
                device.isSmallScreen ? "my-1" : ""
              }`}
            >
              {selectedVariant
                ? selectedVariant?.customerSku
                : priceInfo?.value}
            </p>
            <p className={`card-text fw-400 text-capitalize sku-price`}>
              {selectedVariant
                ? selectedVariant?.currencySign + selectedVariant?.price
                : priceInfo?.currencySign + priceInfo?.price}
              <span className="sku-price mx-1">
                <del>
                  {selectedVariant
                    ? selectedVariant?.currencySign + selectedVariant?.pricelist
                    : priceInfo?.currencySign + priceInfo?.pricelist}
                </del>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GridView
