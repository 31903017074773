import React, { useEffect } from "react"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import useViewport from "@/hooks/useViewport"
import DetailPopUp from "@/components/PartsViewer/v2/DetailPopUp/"
import GridAndListPanel from "@/components/PartsViewer/v2/GridAndListPanel"
import PartsDiagramViewer from "@/components/PartsViewer/v2/PartsDiagramViewer"
import { LoaderSpinner } from "@/components/PartsViewer/v2/icon"
import ErrorFallbackUI from "@/components/PartsViewer/v2/ErrorFallBack"
import PropTypes from "prop-types"

const MobileView = props => {
  const {
    expandHandler,
    partsList,
    setIsListExtend,
    isListExtend,
    title,
    showPopup,
    popupModal,
    setModal,
    productId,
    selected,
    tabsActive,
    panelState,
    onClose,
    partController,
    sku,
    prefeedbackController,
    loading,
    analytics,
    setWidthSize,
  } = props
  const { isDesktop } = useViewport()
  useEffect(() => prefeedbackController(false), [])

  const selectionChange = selection => {
    onClose(false)
    prefeedbackController(selection == "parts")
  }

  if (loading.error) return <ErrorFallbackUI />

  return (
    <>
      <div
        className={`mobile-tabs d-flex flex-column w-100 ${
          tabsActive == "parts" ? "parts-section" : ""
        }`}
      >
        <Tabs
          defaultActiveKey="diagram"
          className="mb-3 mobile-view"
          onSelect={selection => selectionChange(selection)}
        >
          <Tab eventKey="diagram" title="Diagram" className="tab-diagram">
            <div className={`${isListExtend ? "" : "fullscreen-extend"} left`}>
              {!loading?.status ? (
                <PartsDiagramViewer
                  expandHandler={expandHandler}
                  fullscreen={panelState}
                  selectedFile={selected}
                  showPopup={showPopup}
                  partController={partController}
                  sku={sku}
                  title={title}
                />
              ) : (
                <LoaderSpinner />
              )}
            </div>
          </Tab>

          <Tab eventKey="parts" title="Parts">
            <div
              className={`${
                isListExtend ? "fullscreen-extend" : ""
              } fullscreen-right h-100`}
            >
              <GridAndListPanel
                partsList={partsList}
                setIsListExtend={setIsListExtend}
                isListExtend={isListExtend}
                title={title}
                showPopup={showPopup}
                partController={partController}
                sku={sku}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      <div
        className={`mobile-popup ${!isDesktop && popupModal ? "active" : ""}`}
      >
        <DetailPopUp
          productId={productId}
          setModal={setModal}
          partController={partController}
          sku={sku}
          title={title}
          analytics={analytics}
          setWidthSize={setWidthSize}
        />
      </div>
    </>
  )
}

MobileView.propTypes = {
  setWidthSize: PropTypes.func.isRequired,
}

export default MobileView
