import CONSTANTS from "@/constants/index"
import { getSearchPDP } from "@/services/ct.service.js"
import { getUserPersona } from "@/utils/helper.js"

const partsReducer = (state, action) => {
  try {
    switch (action.type) {
      case "initPartsList": {
        const newParts = action.payload.Parts.map(item => ({
          key: item?.Cod,
          partApi: item,
          ctApiPromise: null,
          prices: action?.payload?.prices
            ? action?.payload?.prices[item?.skuId]
            : 0,
        }))

        state.parts = newParts
        const sortAsc = sortByName(state, "asc")
        return { parts: sortAsc }
      }
      case "fetchCT": {
        const { payload } = action
        const newParts = state.parts.map(item => {
          if (payload.includes(item.key) && item.ctApiPromise == null)
            return {
              ...item,
              ctApiPromise: fetchCTRequest(item.partApi, item?.prices),
            }
          return item
        })
        return { parts: newParts }
      }
      case "fetchCTAny": {
        const { payload } = action
        const newParts = []
        let count = 0
        for (const item of state.parts) {
          if (item.ctApiPromise == null && count < payload) {
            newParts.push({
              ...item,
              ctApiPromise: fetchCTRequest(item.partApi, item?.prices),
            })
            count++
          } else newParts.push(item)
        }
        return { parts: newParts }
      }
      case "filterProduct": {
        const { payload } = action
        const persona = getUserPersona()

        let sortedData
        switch (payload) {
          case "asc":
            sortedData = sortByName(state, "asc")
            break
          case "desc":
            sortedData = sortByName(state, "desc")
            break
          case "descPrice":
            sortedData = sortByPriceDesc(state, persona)

            break
          case "ascPrice":
            sortedData = sortByPrice(state, persona, "asc")
            break
        }

        return { parts: sortedData }
      }
      case "fetchForCpiPromise": {
        const { payload } = action
        const newParts = []
        let arraylength = 0
        for (const item of state.parts) {
          arraylength = newParts?.length
          if (item.ctApiPromise == null && arraylength < payload) {
            newParts.push({
              ...item,
              ctApiPromise: fetchCTRequest(item.partApi, item?.prices),
            })
          } else newParts.push(item)
        }

        return { parts: newParts }
      }

      default:
        throw new Error("default")
    }
  } catch (e) {
    return e
  }
}

export default partsReducer

const sortByName = (state, order) => {
  switch (order) {
    case "desc":
      return state.parts.sort((a, b) => {
        if (a?.partApi?.Name < b?.partApi?.Name) return 1
        if (a?.partApi?.Name > b?.partApi?.Name) return -1
        return 0
      })
    case "asc":
      return state.parts.sort((a, b) => {
        if (a.partApi?.Name > b.partApi?.Name) return 1
        if (a.partApi?.Name < b.partApi?.Name) return -1
        return 0
      })
  }
}

const sortByPrice = (state, persona, order = "asc") => {
  state.parts.map(part => ({
    ...part,
    prices: part.prices.sort(
      (a, b) => a?.prices[persona]?.centAmount - b?.prices[persona]?.centAmount
    ),
  }))
  const sortingPrice = personaPriceSort(persona)
  return state.parts.sort(
    (a, b) => a.partApi[sortingPrice] - b.partApi[sortingPrice]
  )
}

const sortByPriceDesc = (state, persona) => {
  state.parts.map(part => ({
    ...part,
    prices: part.prices.sort(
      (a, b) => b?.prices[persona]?.centAmount - a?.prices[persona]?.centAmount
    ),
  }))
  const sortingPrice = personaPriceSort(persona)
  return state.parts.sort(
    (a, b) => b.partApi[sortingPrice] - a.partApi[sortingPrice]
  )
}

export const getActions = (state, dispatch) => {
  return {
    initPartsList: payload => dispatch({ type: "initPartsList", payload }),
    fetchCT: payload => dispatch({ type: "fetchCT", payload }),
    fetchCTAny: payload => dispatch({ type: "fetchCTAny", payload }),
    filterProduct: payload => dispatch({ type: "filterProduct", payload }),
    fetchForCpiPromise: payload =>
      dispatch({ type: "fetchForCpiPromise", payload }),
    get: {
      readyEntries: () => {
        return state?.parts.filter(item => item?.ctApiPromise != null)
      },
      firstEntries: key => {
        return state?.parts
          .filter(item => item?.ctApiPromise != null)
          ?.splice(0, key)
      },
      entryByKey: key => {
        const data = state?.parts.filter(item => item?.key == key)?.[0]
        if (data?.ctApiPromise === null) {
          return {
            ...data,
            ctApiPromise: fetchCTRequest(data?.partApi, data?.prices),
          }
        }
        return data
      },
    },
  }
}

export const fetchCTRequest = async (args, prices) => {
  try {
    const docs = await getSearchDetails(args, prices)
    const data = {
      ...args,
      productDiscontinue:
        Date.parse(docs[0]?.productDiscontinuedDate_dt) < Date.now(),
      productIdKey: docs?.[0]?.["ProductNoSpecialKeyword"]
        ? docs?.[0]?.["ProductNoSpecialKeyword"]
        : docs?.[0]?.["CustomerFacingSKU_s"],
      imageUrl: docs?.[0]?.["Color.KEMP.Details_ss"]?.[0]?.split("||")?.[1]
        ? docs[0]["Color.KEMP.Details_ss"][0]?.split("||")[1]
        : null,
      activeItem: docs?.[0]?.["Color.KEMP.Details_ss"]?.[0]
        ?.split("||")[0]
        ?.split("|")[0],
      data: docs[0],
    }
    return data
  } catch (err) {
    return err
  }
}

const getSearchDetails = async (args, prices) => {
  const { slug } = args
  const resp = await getSearchPDP(slug)
  const persona = getUserPersona()
  const {
    data: {
      response: { docs },
    },
  } = resp

  if (docs.length != 0) {
    docs[0].prices = prices
    const varientDetails = getVarientDetails(docs[0])
    const fieldType = varientDetails[0]?.fieldType
    const swatches = docs[0][fieldType]?.map((val, i) => {
      let price = 0
      let pricelist = 0
      let currencyCode = "USD"
      let currencySign = "$"
      const [label, swatch, id, , , value] = val.split("|")
      const sortPrice = docs[0]?.prices?.filter(val => val?.sku === id)[0]
      currencyCode = sortPrice?.prices[persona]?.currencyCode
      currencySign = sortPrice?.currencySign
      const centPrice = sortPrice?.prices[persona]?.centAmount ?? 0
      pricelist = Number(
        (sortPrice?.prices["ANY"]?.centAmount ?? 0) / 100
      ).toFixed(2)
      price = Number(centPrice / 100).toFixed(2)
      const colorBaseUrl = CONSTANTS.COLOR_FILE_URL
      const colorUrl = colorBaseUrl + swatch
      const imgUrl = docs[0]["productImages.url_ss"]
        ? docs[0]["productImages.url_ss"][0]
        : null
      return {
        checked: false,
        colorUrl,
        discontinuedColor: false,
        id,
        label,
        name: "product-color",
        notApplicable: false,
        value,
        price,
        pricelist,
        currencyCode,
        currencySign,
        imgUrl,
        centPrice,
      }
    })
    docs[0].colorData = swatches
    docs[0].varientDetails = varientDetails
    return docs
  } else return docs
}

const getVarientDetails = data => {
  if (data["Color.SKU.Details_ss"]) {
    return data["Color.SKU.Details_ss"].map(color => {
      const d = color.split("|")
      return {
        name: d[0],
        swatch: d[1],
        sku: d[2],
        isStock: d[3],
        discontinued: !(d[4] === "false" || Date.parse(d[4]) > Date.now()),
        customerSku: d[5],
        fieldType: "Color.SKU.Details_ss",
      }
    })
  } else if (data["Color.SKU.Details_s"]) {
    const d = data["Color.SKU.Details_s"].split("|")
    return [
      {
        name: d[0],
        swatch: d[1],
        sku: d[2],
        isStock: d[3],
        discontinued: !(d[4] === "false" || Date.parse(d[4]) > Date.now()),
        customerSku: d[5],
        fieldType: "Color.SKU.Details_s",
      },
    ]
  } else if (data["SKU.Details_ss"]) {
    return data["SKU.Details_ss"].map(color => {
      const d = color.split("|")
      return {
        name: "Not Applicable",
        swatch: "swatch_NA",
        sku: d[0],
        isStock: d[1],
        discontinued: !(d[2] === "false" || Date.parse(d[2]) > Date.now()),
        customerSku: d[3],
        fieldType: "SKU.Details_ss",
      }
    })
  }
}
const personaPriceSort = persona => {
  switch (persona) {
    case "GST":
      return "guestPrice"
    case "CTR":
      return "ctrPrice"
    case "FF":
      return "ffPrice"
    case "KEMP":
      return "kemprPrice"
    case "TPR":
      return "tprPrice"
    case "TVR":
      return "tvrPrice"
    default:
      return "guestPrice"
  }
}
