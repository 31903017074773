import React from "react"
import spinnerAnimation from "@/public/images/spinner.svg"
export const IconReset = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5625 26.25V24.625C11.9583 24.3958 9.83333 23.4583 8.1875 21.8125C6.54167 20.1667 5.60417 18.0417 5.375 15.4375H3.75V14.5625H5.375C5.60417 11.9583 6.54167 9.83333 8.1875 8.1875C9.83333 6.54167 11.9583 5.60417 14.5625 5.375V3.75H15.4375V5.375C18.0417 5.60417 20.1667 6.54167 21.8125 8.1875C23.4583 9.83333 24.3958 11.9583 24.625 14.5625H26.25V15.4375H24.625C24.3958 18.0417 23.4583 20.1667 21.8125 21.8125C20.1667 23.4583 18.0417 24.3958 15.4375 24.625V26.25H14.5625ZM15 23.75C17.4167 23.75 19.4792 22.8958 21.1875 21.1875C22.8958 19.4792 23.75 17.4167 23.75 15C23.75 12.5833 22.8958 10.5208 21.1875 8.8125C19.4792 7.10417 17.4167 6.25 15 6.25C12.5833 6.25 10.5208 7.10417 8.8125 8.8125C7.10417 10.5208 6.25 12.5833 6.25 15C6.25 17.4167 7.10417 19.4792 8.8125 21.1875C10.5208 22.8958 12.5833 23.75 15 23.75ZM15 18.375C14.0719 18.375 13.2773 18.0445 12.6164 17.3836C11.9555 16.7227 11.625 15.9281 11.625 15C11.625 14.0719 11.9555 13.2773 12.6164 12.6164C13.2773 11.9555 14.0719 11.625 15 11.625C15.9281 11.625 16.7227 11.9555 17.3836 12.6164C18.0445 13.2773 18.375 14.0719 18.375 15C18.375 15.9281 18.0445 16.7227 17.3836 17.3836C16.7227 18.0445 15.9281 18.375 15 18.375Z"
      fill="#ffffff"
    />
  </svg>
)
export const IconZoomIn = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 15.5V25H15.5V15.5H25V14.5H15.5V5H14.5V14.5H5V15.5H14.5Z"
      fill="#ffffff"
    />
    <path
      d="M14.5 15.5V25H15.5V15.5H25V14.5H15.5V5H14.5V14.5H5V15.5H14.5Z"
      fill="#ffffff"
    />
  </svg>
)
export const IconZoomOut = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 14.5H25V15.5H5V14.5Z" fill="#ffffff" />
    <path d="M5 14.5H25V15.5H5V14.5Z" fill="#ffffff" />
  </svg>
)

export const IconExpand = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6985 16.2919L13.4056 16.999L8.46951 21.9341L12.7302 21.9341V22.9341H6.7793L6.7793 16.9832H7.7793L7.77937 21.2101L12.6985 16.2919ZM22.3215 6.76526V12.7162H21.3215L21.3203 8.48826L16.1524 13.6575L15.4453 12.9504L20.6303 7.76426L16.3706 7.76526V6.76526H22.3215Z"
      fill="#ffffff"
    />
  </svg>
)

export const IconExpandGrid = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.91917 10.2918L6.62628 10.9989L1.69021 15.934L5.95093 15.934V16.934H0L0 10.9831H1L1.00007 15.2099L5.91917 10.2918ZM15.5422 0.765137V6.71606H14.5422L14.541 2.48814L9.37307 7.65737L8.66596 6.95027L13.851 1.76414L9.59131 1.76514V0.765137H15.5422Z"
      fill="#212121"
    />
  </svg>
)
export const IconCollapse = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.35338 17.4951L0.586914 16.7291L6.23617 11.0833H2.49991V10H8.0866V15.5833H7.00263V11.8493L1.35338 17.4951Z"
      fill="#ffffff"
    />
    <path
      d="M10.0879 7.99515V2.41183H11.1719V6.14583L16.8211 0.5L17.5876 1.266L11.9383 6.91183H15.6746V7.99515H10.0879Z"
      fill="#ffffff"
    />
  </svg>
)

export const ResetActive = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5625 26.25V24.625C11.9583 24.3958 9.83333 23.4583 8.1875 21.8125C6.54167 20.1667 5.60417 18.0417 5.375 15.4375H3.75V14.5625H5.375C5.60417 11.9583 6.54167 9.83333 8.1875 8.1875C9.83333 6.54167 11.9583 5.60417 14.5625 5.375V3.75H15.4375V5.375C18.0417 5.60417 20.1667 6.54167 21.8125 8.1875C23.4583 9.83333 24.3958 11.9583 24.625 14.5625H26.25V15.4375H24.625C24.3958 18.0417 23.4583 20.1667 21.8125 21.8125C20.1667 23.4583 18.0417 24.3958 15.4375 24.625V26.25H14.5625ZM15 23.75C17.4167 23.75 19.4792 22.8958 21.1875 21.1875C22.8958 19.4792 23.75 17.4167 23.75 15C23.75 12.5833 22.8958 10.5208 21.1875 8.8125C19.4792 7.10417 17.4167 6.25 15 6.25C12.5833 6.25 10.5208 7.10417 8.8125 8.8125C7.10417 10.5208 6.25 12.5833 6.25 15C6.25 17.4167 7.10417 19.4792 8.8125 21.1875C10.5208 22.8958 12.5833 23.75 15 23.75ZM15 18.375C14.0719 18.375 13.2773 18.0445 12.6164 17.3836C11.9555 16.7227 11.625 15.9281 11.625 15C11.625 14.0719 11.9555 13.2773 12.6164 12.6164C13.2773 11.9555 14.0719 11.625 15 11.625C15.9281 11.625 16.7227 11.9555 17.3836 12.6164C18.0445 13.2773 18.375 14.0719 18.375 15C18.375 15.9281 18.0445 16.7227 17.3836 17.3836C16.7227 18.0445 15.9281 18.375 15 18.375ZM15 17.5C15.6875 17.5 16.276 17.2552 16.7656 16.7656C17.2552 16.276 17.5 15.6875 17.5 15C17.5 14.3125 17.2552 13.724 16.7656 13.2344C16.276 12.7448 15.6875 12.5 15 12.5C14.3125 12.5 13.724 12.7448 13.2344 13.2344C12.7448 13.724 12.5 14.3125 12.5 15C12.5 15.6875 12.7448 16.276 13.2344 16.7656C13.724 17.2552 14.3125 17.5 15 17.5Z"
      fill="#00BCE5"
    />
  </svg>
)

export const LoadingImage = () => (
  <div className="loading-spinner">
    <svg
      width="56"
      height="56"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30"
        cy="30"
        r="28"
        stroke="url(#paint0_linear_952_180)"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_952_180"
          x1="54.1411"
          y1="49.3794"
          x2="9.49243"
          y2="8.51453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1AAA89" />
          <stop offset="0.237209" stopColor="#08A1A9" />
          <stop offset="0.508047" stopColor="#1E80B7" />
          <stop offset="0.784094" stopColor="#693A92" />
          <stop offset="1" stopColor="#D81081" />
        </linearGradient>
      </defs>
    </svg>
  </div>
)

export const CloseIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.76087 8.49989L0 15.9245L0.73913 16.6316L8.5 9.207L16.2609 16.6316L17 15.9245L9.23913 8.49989L17 1.07527L16.2609 0.368164L8.5 7.79278L0.739131 0.368164L0 1.07527L7.76087 8.49989Z"
        fill="#212121"
      />
      <path
        d="M7.76087 8.49989L0 15.9245L0.73913 16.6316L8.5 9.207L16.2609 16.6316L17 15.9245L9.23913 8.49989L17 1.07527L16.2609 0.368164L8.5 7.79278L0.739131 0.368164L0 1.07527L7.76087 8.49989Z"
        fill="#212121"
      />
    </svg>
  )
}

export const DownIcon = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1504 0L15.8799 0.683941L8.01516 9.07303L0.150391 0.683941L0.879928 0L8.01516 7.61097L15.1504 0Z"
        fill="#757575"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1504 0L15.8799 0.683941L8.01516 9.07303L0.150391 0.683941L0.879928 0L8.01516 7.61097L15.1504 0Z"
        fill="#212121"
      />
    </svg>
  )
}

export const PlusIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 13.75V7.74995H0.25V6.25H6.25V0.25H7.74995V6.25H13.75V7.74995H7.74995V13.75H6.25Z"
      fill="#757575"
    />
  </svg>
)

export const MinusIcon = () => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.25 1.74995V0.25H13.75V1.74995H0.25Z" fill="#CCCCCC" />
  </svg>
)

export const SortIcon = () => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.23755 12.703V11.203H10.7375V12.703H7.23755ZM3.23755 7.51064V6.01068H14.7375V7.51064H3.23755ZM0.237549 2.31833V0.818359H17.7375V2.31833H0.237549Z"
      fill="#1C1B1F"
    />
  </svg>
)
export const SortByIcon = () => (
  <svg
    width="21"
    height="30"
    viewBox="0 0 21 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5 12.5025C14.2917 12.5309 13.2375 11.6876 13 10.5025H0V9.50251H13C13.2366 8.33734 14.2611 7.5 15.45 7.5C16.6389 7.5 17.6634 8.33734 17.9 9.50251H21V10.5025H17.95C17.7159 11.6688 16.6896 12.5067 15.5 12.5025ZM15.5 8.50293C14.6716 8.50293 14 9.1745 14 10.0029C14 10.8314 14.6716 11.5029 15.5 11.5029C16.3284 11.5029 17 10.8314 17 10.0029C17 9.1745 16.3284 8.50293 15.5 8.50293Z"
      fill="#494949"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 19.5278C3.2264 18.3507 4.25636 17.5 5.455 17.5C6.65364 17.5 7.6836 18.3507 7.91 19.5278H21V20.5278H7.95C7.75555 21.7579 6.68544 22.6572 5.44026 22.6371C4.19509 22.617 3.15459 21.6835 3 20.4478H0V19.4478L3 19.5278ZM5.45996 21.5273C6.28839 21.5273 6.95996 20.8558 6.95996 20.0273C6.95996 19.1989 6.28839 18.5273 5.45996 18.5273C4.63153 18.5273 3.95996 19.1989 3.95996 20.0273C3.96517 20.4287 4.13103 20.8113 4.42045 21.0894C4.70987 21.3675 5.0987 21.5181 5.49996 21.5073L5.45996 21.5273Z"
      fill="#494949"
    />
  </svg>
)

export const ImageThumbIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-0.000244141 6.20115V0.201172H5.99974V6.20115H-0.000244141ZM-0.000244141 13.8011V7.80115H5.99974V13.8011H-0.000244141ZM7.59974 6.20115V0.201172H13.5997V6.20115H7.59974ZM7.59974 13.8011V7.80115H13.5997V13.8011H7.59974Z"
      fill="#757575"
    />
  </svg>
)

export const ImageListIcon = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.000244141 11.4015V10.2016H14.0002V11.4015H0.000244141ZM0.000244141 8.20152V7.00156H14.0002V8.20152H0.000244141ZM0.000244141 5.00152V3.80156H14.0002V5.00152H0.000244141ZM0.000244141 1.80152V0.601562H14.0002V1.80152H0.000244141Z"
      fill="#757575"
    />
  </svg>
)
export const CrossButton = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1.5L14.5 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14.5 1.5L1.5 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const ModalCloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.40002 13.6553L0.346191 12.6015L5.94619 7.00148L0.346191 1.40148L1.40002 0.347656L7.00002 5.94766L12.6 0.347656L13.6538 1.40148L8.05384 7.00148L13.6538 12.6015L12.6 13.6553L7.00002 8.05531L1.40002 13.6553Z"
        fill="#757575"
      />
    </svg>
  )
}

export const ExitFullScreenIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40 25C48.2843 25 55 31.7157 55 40C55 48.2843 48.2843 55 40 55C31.7157 55 25 48.2843 25 40C25 31.7157 31.7157 25 40 25Z"
      stroke="#3FC28F"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const IconApiError = () => {
  return (
    <svg
      className="icon-api-error"
      viewBox="0 0 168 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999695 75.6734C0.999695 116.914 34.4321 150.347 75.6731 150.347C116.914 150.347 150.347 116.914 150.347 75.6734C150.347 34.4324 116.914 1 75.6731 1C34.4321 1 0.999695 34.4324 0.999695 75.6734ZM-0.000305176 75.6734C-0.000305176 117.467 33.8798 151.347 75.6731 151.347C96.8809 151.347 116.051 142.623 129.791 128.567L166.603 168.287L167.337 167.607L130.486 127.847C143.412 114.271 151.347 95.8987 151.347 75.6734C151.347 33.8801 117.466 0 75.6731 0C33.8798 0 -0.000305176 33.8801 -0.000305176 75.6734ZM61.0059 59.2387C61.2988 58.9458 61.7737 58.9458 62.0666 59.2387L77.8234 74.9955L93.5802 59.2387C93.8731 58.9458 94.3479 58.9458 94.6408 59.2387C94.9337 59.5316 94.9337 60.0065 94.6408 60.2994L78.884 76.0562L94.6407 91.8129C94.9336 92.1058 94.9336 92.5806 94.6407 92.8735C94.3478 93.1664 93.873 93.1664 93.5801 92.8735L77.8234 77.1168L62.0667 92.8735C61.7738 93.1664 61.2989 93.1664 61.006 92.8735C60.7131 92.5806 60.7131 92.1058 61.006 91.8129L76.7627 76.0562L61.0059 60.2994C60.713 60.0065 60.713 59.5316 61.0059 59.2387Z"
        fill="url(#paint0_linear_2074_15327)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2074_15327"
          x1="0.223115"
          y1="0"
          x2="167.337"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1AAA89" />
          <stop offset="0.237209" stopColor="#08A1A9" />
          <stop offset="0.508047" stopColor="#1E80B7" />
          <stop offset="0.784094" stopColor="#693A92" />
          <stop offset="1" stopColor="#D81081" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const ImageGridViewMobile = () => (
  <svg
    width="17"
    height="30"
    viewBox="0 0 17 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="grid icons/grid filled">
      <g id="Path">
        <path d="M7 6H0V13H7V6Z" fill="#212121" />
        <path d="M17 6H10V13H17V6Z" fill="#212121" />
        <path d="M0 16H7V23H0V16Z" fill="#212121" />
        <path d="M17 16H10V23H17V16Z" fill="#212121" />
      </g>
    </g>
  </svg>
)
export const ImageListViewMobile = () => (
  <svg
    width="17"
    height="30"
    viewBox="0 0 17 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="grid icons/vertical empty">
      <g id="Path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-1.74846e-07 10L17 10L17 6L0 6L-1.74846e-07 10ZM16 7L16 9L1 9L1 7L16 7Z"
          fill="#212121"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-7.43094e-07 23L17 23L17 19L-5.68248e-07 19L-7.43094e-07 23ZM16 20L16 22L0.999999 22L0.999999 20L16 20Z"
          fill="#212121"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17 16.5L-4.5897e-07 16.5L-2.84124e-07 12.5L8.5 12.5L17 12.5L17 16.5ZM16 15.5L16 13.5L1 13.5L1 15.5L16 15.5Z"
          fill="#212121"
        />
      </g>
    </g>
  </svg>
)

export const SortByDropDownIcon = () => (
  <svg
    width="13"
    height="30"
    viewBox="0 0 13 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1814 11L13 11.6448L6.5 18.8179L0 11.6448L0.782276 11L6.5 17.2061L12.1814 11Z"
      fill="#757575"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1814 11L13 11.6448L6.5 18.8179L0 11.6448L0.782276 11L6.5 17.2061L12.1814 11Z"
      fill="#494949"
    />
  </svg>
)
export const IconClose = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7609 15.4999L7 22.9245L7.73913 23.6316L15.5 16.207L23.2609 23.6316L24 22.9245L16.2391 15.4999L24 8.07527L23.2609 7.36816L15.5 14.7928L7.73913 7.36816L7 8.07527L14.7609 15.4999Z"
      fill="#757575"
    />
    <path
      d="M14.7609 15.4999L7 22.9245L7.73913 23.6316L15.5 16.207L23.2609 23.6316L24 22.9245L16.2391 15.4999L24 8.07527L23.2609 7.36816L15.5 14.7928L7.73913 7.36816L7 8.07527L14.7609 15.4999Z"
      fill="#757575"
    />
  </svg>
)

export const IncIcon = () => (
  <svg
    width="14"
    height="30"
    viewBox="0 0 14 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 15.4995V21.9995H7.5V15.4995H14V14.4995H7.5V7.99951H6.5V14.4995H0V15.4995H6.5Z"
      fill="#212121"
    />
    <path
      d="M6.5 15.4995V21.9995H7.5V15.4995H14V14.4995H7.5V7.99951H6.5V14.4995H0V15.4995H6.5Z"
      fill="#212121"
    />
  </svg>
)

export const DecIcon = () => (
  <svg
    width="14"
    height="30"
    viewBox="0 0 14 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 14.4995H14V15.4995H0V14.4995Z"
      fill="#757575"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 14.4995H14V15.4995H0V14.4995Z"
      fill="#212121"
    />
  </svg>
)
export const LoadingIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="spinner-border loadingIcon"
    ></svg>
  )
}

export const WarnIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="B4-Cart"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="D_Kohler_UI_Batch-4,-Cart-Landing-Out-of-Stock-Promo-Error-ZipCode-1.2"
          transform="translate(-806.000000, -1190.000000)"
          fill="#D10000"
        >
          <g id="Cart-Products" transform="translate(70.000000, 395.000000)">
            <g id="Product-Robern" transform="translate(0.000000, 644.000000)">
              <g
                id="03-Buttons/AlertMsg"
                transform="translate(731.000000, 146.000000)"
              >
                <path
                  d="M15.8865,19.128 L15.8865,21 L14.3565,21 L14.3565,19.128 L15.8865,19.128 Z M15.8865,8.148 L15.8865,17.454 L14.3565,17.454 L14.3565,8.148 L15.8865,8.148 Z"
                  id="Tool-Tip-Icon"
                ></path>
                <path
                  d="M15,5 C20.5228475,5 25,9.4771525 25,15 C25,20.5228475 20.5228475,25 15,25 C9.4771525,25 5,20.5228475 5,15 C5,9.4771525 9.4771525,5 15,5 Z M15,5.66666667 C9.84534234,5.66666667 5.66666667,9.84534234 5.66666667,15 C5.66666667,20.1546577 9.84534234,24.3333333 15,24.3333333 C20.1546577,24.3333333 24.3333333,20.1546577 24.3333333,15 C24.3333333,9.84534234 20.1546577,5.66666667 15,5.66666667 Z"
                  id="Oval"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export const BackToTop = () => (
  <svg
    width="10"
    height="30"
    viewBox="0 0 10 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.95028 9.99955L5.00053 5.0498L0.0507812 9.99955L0.757888 10.7067L4.50053 6.96402V23.9996H5.50053V6.96402L9.24317 10.7067L9.95028 9.99955Z"
      fill="#212121"
    />
  </svg>
)

export const LoaderSpinner = () => {
  return (
    <div className="spinner-img d-flex justify-content-center align-items-center h-100">
      <img src={spinnerAnimation.src} alt="loading" />
    </div>
  )
}

export const ListViewSelected = () => (
  <svg
    width="17"
    height="30"
    viewBox="0 0 17 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 10L-1.74846e-07 10L0 6L17 6L17 10Z" fill="#212121" />
    <path
      d="M17 23L-7.43094e-07 23L-5.68248e-07 19L17 19L17 23Z"
      fill="#212121"
    />
    <path
      d="M-4.5897e-07 16.5L17 16.5L17 12.5L-2.84124e-07 12.5L-4.5897e-07 16.5Z"
      fill="#212121"
    />
  </svg>
)

export const GridViewUnSelected = () => (
  <svg
    width="17"
    height="30"
    viewBox="0 0 17 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 6V13H0V6H7ZM6 7H1V12H6V7Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 6V13H10V6H17ZM16 7H11V12H16V7Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 23V16H0V23H7ZM1 17H6V22H1V17Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 16V23H10V16H17ZM16 17H11V22H16V17Z"
      fill="#212121"
    />
  </svg>
)

export const CartCloseIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7609 15.4989L7 22.9235L7.73913 23.6306L15.5 16.206L23.2609 23.6306L24 22.9235L16.2391 15.4989L24 8.07429L23.2609 7.36719L15.5 14.7918L7.73913 7.36719L7 8.07429L14.7609 15.4989Z"
      fill="white"
    />
    <path
      d="M14.7609 15.4989L7 22.9235L7.73913 23.6306L15.5 16.206L23.2609 23.6306L24 22.9235L16.2391 15.4989L24 8.07429L23.2609 7.36719L15.5 14.7918L7.73913 7.36719L7 8.07429L14.7609 15.4989Z"
      fill="white"
    />
  </svg>
)
